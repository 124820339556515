/* Lightbox */
/* line 7, ../sass/lightbox.sass */
body:after {
  content: url(https://fact.snsolutions.fr/online_assets/close.png) url(https://fact.snsolutions.fr/online_assets/loading2.gif) url(https://fact.snsolutions.fr/online_assets/prev.png) url(https://fact.snsolutions.fr/online_assets/next.png);
  display: none;
}

/* line 11, ../sass/lightbox.sass */
.lightboxOverlay {
  min-height: 3600px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

/* line 20, ../sass/lightbox.sass */
.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}
/* line 28, ../sass/lightbox.sass */
.lightbox .lb-image {
  display: block;
  height: auto;
	border: 3px solid #FFF;
	-moz-box-shadow: inset 2px 2px 5px #000;
	box-shadow: inset 2px 2px 5px #000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
/* line 32, ../sass/lightbox.sass */
.lightbox a img {
  border: none;
}

/* line 35, ../sass/lightbox.sass */
.lb-outerContainer {
  position: relative;
  background-color: none;
  zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
/* line 38, ../../../../.rvm/gems/ruby-1.9.3-p392/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/utilities/general/_clearfix.scss */
.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

/* line 44, ../sass/lightbox.sass */
.lb-container {
  padding: 4px;
  position: relative;
}
/* Choa magnifier glass */
.img-magnifier-glass {
  position: absolute;
  border: 3px solid #FFF;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 160px;
  height: 160px;
}

/* line 47, ../sass/lightbox.sass */
.lb-loader {
  position: absolute;
  top: 43%;
  left: 0%;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

/* line 56, ../sass/lightbox.sass */
.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(https://fact.snsolutions.fr/online_assets/loading2.gif) no-repeat;
}

/* line 63, ../sass/lightbox.sass */
.lb-nav {
/*
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
 */
  top: 0;
  z-index: 10;
}

/* line 71, ../sass/lightbox.sass */
.lb-container > .nav {
  left: 0;
}

/* line 74, ../sass/lightbox.sass */
.lb-nav a {
  outline: none;
}

/* line 77, ../sass/lightbox.sass */
.lb-prev, .lb-next {
  width: 64px;
  height: 100%;
  cursor: pointer;
  /* Trick IE into showing hover */
  display: block;
}

/* line 84, ../sass/lightbox.sass */
.lb-prev {
/* float: left; */
  position: fixed; /* old: absolute */
/*  top: 36%; */
  left: 0;
  background: url(https://fact.snsolutions.fr/online_assets/prev.png) left 50% no-repeat;
}
/* line 87, ../sass/lightbox.sass */
.lb-prev:hover {
  background: url(https://fact.snsolutions.fr/online_assets/prev.png) left 50% no-repeat;
  opacity: 0.9;
}

/* line 90, ../sass/lightbox.sass */
.lb-next {
/* float: right; */
  position: fixed; /* old: absolute */
/*  top: 36%; */
  right: 0;
  background: url(https://fact.snsolutions.fr/online_assets/next.png) right 50% no-repeat;
}
/* line 93, ../sass/lightbox.sass */
.lb-next:hover {
  background: url(https://fact.snsolutions.fr/online_assets/next.png) right 50% no-repeat;
  opacity: 0.9;
}

/* line 96, ../sass/lightbox.sass */

.lb-dataContainer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
  background: rgba(51, 51, 51, 0.6);
  margin: 0 auto;
  padding-top: 5px;
  zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
/* line 38, ../../../../.rvm/gems/ruby-1.9.3-p392/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/utilities/general/_clearfix.scss */
.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

/* line 103, ../sass/lightbox.sass */
.lb-data {
  padding: 0 4px;
  color: #bbbbbb;
}
/* line 106, ../sass/lightbox.sass */
.lb-data .lb-details {
  width: 36%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}
/* line 111, ../sass/lightbox.sass */
.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}
/* line 115, ../sass/lightbox.sass */
.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}
/* line 121, ../sass/lightbox.sass */
.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(https://fact.snsolutions.fr/online_assets/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}
/* line 130, ../sass/lightbox.sass */
.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
/* Fix for bootstrap */
.lb-image{   max-width: inherit; }

.lb-data .lb-rotate {
  display: block;
  float: right;
  width: 32px;
  height: 32px;
  background: url(https://fact.snsolutions.fr/online_assets/rotate.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
}
/* line 130, ../sass/lightbox.sass */
.lb-data .lb-rotate:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
.lb-data .lb-zoomIn, .lb-data .lb-zoomOut, .lb-data .lb-download, .lb-data .lb-validate-camp, .lb-data .lb-validate-docs, .lb-data .lb-magnify {
    display: block;
    float: right;
    width: 32px;
    height: 32px;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
}
.lb-data .lb-zoomIn:hover, .lb-data .lb-zoomOut:hover, .lb-data .lb-download:hover, .lb-data .lb-validate-camp:hover, .lb-data .lb-validate-docs:hover, .lb-data .lb-magnify:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}