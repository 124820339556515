.adminOnly, .managerOnly, .accountantOnly, .userOnly, .adminOnlyFlex, .managerOnlyFlex, .accountantOnlyFlex, .userOnlyFlex, .workerOnly, .workerOnlyFlex {
	display: none;
}
body {
	font-size: 0.8rem;
}
/* .home-page-content { font-size: 1rem;} */
#routerContainer {
	height: 100%;
}
.topnav .navbar-brand img {
	height: auto;
}
.home-links {
	text-decoration: none !important;
    display:flex;
    justify-content: center;
    align-items: center;
	width: 18em;
	height: 18em;
}
.home-links:hover {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.6) !important;
}
@media (max-width: 1600px) {
	.home-links {
		width: 15em;
		height: 15em;
	}
}
.text-large {
	font-size: 2em !important;
}
.text-medium {
	font-size: 1.5em !important;
}
.smaller {
	font-size: 0.8em !important;
}
.btn-block {
	width: 100% !important;
}
.centeredBox {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 96%;
	display: flex;
}
.centeredAll {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 96%;
	display: flex;
}
footer, header, .shadow-line {
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
}
.shadow-xl {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.6) !important;
}
.shadow-danger {
	box-shadow: 0 0.6rem 1.2rem rgba(234, 4, 4, 0.6) !important;
}
.bg-login-image {
	background-image: url('../img/bg-login.jpg');
}
.bg-img-contain {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.bg-img-cover {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}
.bg-password-image, .bg-register-image {
	background-position: 50%;
	background-size: cover;
}
.loading {
	padding: 6rem;
}
.nav-tabs, .nav-pills {
	font-size: 1.2em;
}
.required-input {
	color: red;
}
.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.float-none {
	float: none !important;
}
@media (min-width: 576px) {
	.modal-xxl, .modal-full {
		max-width: 100%;
		margin: 1.75rem 2rem;
	}
}
@media (min-width: 1200px) {
	.modal-xxl {
		max-width: 84%;
		margin: 1.75rem 8%;
	}
}
.modal-on-top {
	z-index: 1060;
}
.popover {
	z-index: 1061;
}
.bg-gradient-primary{background-color:#089fe3;background-image:linear-gradient(180deg,#089fe3 10%,#36b9cc);background-size:cover;}
.bg-gradient-secondary{background-color:#858796;background-image:linear-gradient(180deg,#858796 10%,#60616f);background-size:cover;}
.bg-gradient-success{background-color:#1cc88a;background-image:linear-gradient(180deg,#1cc88a 10%,#13855c);background-size:cover;}
.bg-gradient-info{background-color:#36b9cc;background-image:linear-gradient(180deg,#36b9cc 10%,#258391);background-size:cover;}
.bg-gradient-warning{background-color:#f6c23e;background-image:linear-gradient(180deg,#f6c23e 10%,#dda20a);background-size:cover;}
.bg-gradient-danger{background-color:#e74a3b;background-image:linear-gradient(180deg,#e74a3b 10%,#be2617);background-size:cover;}
.bg-gradient-light{background-color:#f8f9fc;background-image:linear-gradient(180deg,#f8f9fc 10%,#c2cbe5);background-size:cover;}
.bg-gradient-dark{background-color:#5a5c69;background-image:linear-gradient(180deg,#5a5c69 10%,#373840);background-size:cover;}
.bg-gradient-darker{background-color:#363d47;background-image:linear-gradient(180deg,rgb(54, 61, 71) 10%,#212832);background-size:cover}
.bg-gradient-secondary-to-dark {
	background-color: #858796 !important;
	background-image: linear-gradient(135deg, #858796 0%, rgba(33, 40, 50, 0.8) 100%) !important;
}
.bg-gradient-dark-to-secondary {
	background-color: #212832 !important;
	background-image: linear-gradient(135deg,#212832 0%, rgba(133, 135, 150, 0.8) 100%) !important;
}
.bg-gradient-dark-to-grey {
	background-color: #212832 !important;
	background-image: linear-gradient(135deg,#212832 0%, rgba(54, 61, 71, 0.8) 100%) !important;
}
.bg-gradient-primary-to-secondary {
	background-color: #089fe3 !important;
	background-image: linear-gradient(135deg, #089fe3 0%, rgba(183, 12, 127, 0.8) 100%) !important;
}
.bg-gradient-primary-to-info {
	background-color: #089fe3 !important;
	background-image: linear-gradient(135deg, #089fe3 0%, rgba(37, 131, 145, 0.8) 100%) !important;
}
.sidenav-logo {
	padding: 1.5rem 0.5rem;
}
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-img {
	line-height: 3.2rem;
	height: 3rem;
	width: 3rem;
	margin-right: 0.6rem;
	border-radius: 100%;
}
nav .btn .badge {
	position: relative;
	top: -6px;
	right: 6px;
}
nav .badge {
	border-radius: 50%;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon svg {
	font-size: 2rem;
	height: 2rem;
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-icon, .dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-img {
	height: 3rem;
	width: 3rem;
}
@media (min-width: 576px) {
	.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-text {
		max-width: 26rem;
	}
}
.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-details {
	color: #a7aeb8;
	font-size: 1rem;
}
a.go-up-fixed {
	display: none;
	position: fixed;
	bottom: 0px;
	right: 0px;
	border:none;
	border-radius:50%;
	margin:4px;
	color:#fff;
	text-align:center;
	background-color:#33b7cc;
	opacity:0.6;
	z-index: 900;
	width: 3rem;
	height: 3rem;
	line-height: 3.5rem;
}
a.go-up-fixed:hover {
	opacity:1;
}
*:disabled, input:read-only, textarea:read-only {
	cursor: not-allowed;
}
input[type="range"] {
	cursor: pointer;
}
.dt-buttons button {
	padding: 0.5rem 0.75rem;
	font-size: 0.75rem;
	border-radius: 0.25rem;
}
@media (max-width: 768px) {
	.card-body {
		padding: 1.35rem 0.2rem;
	}
}
.profile-user-initials, .backup-pills {
	line-height: 6rem;
	height: 6rem;
	width: 6rem;
	border-radius: 100%;
}
a.backup-pills {
	cursor: pointer;
	text-decoration: none;
}
.profile-user-img {
	width: 12rem;
	border-radius: 25%;
}
.profile-clients-img {
	width:100%;
	max-width: 12rem;
}
@media (max-width: 1200px) {
	.profile-user-img {
		width: 16rem;
		float: right;
	}
}
.profile-user-img:hover, .profile-clients-img:hover, .org-user-img:hover {
	opacity: 0.6;
}
.org-user-img {
	width: 9rem;
	border-radius: 25%;
}
@media (min-width: 1200px) {
	.type-act-select {
		max-width: 16%;
	}
}
.card-header {
	font-size: 1.2rem;
}
.card:not([class*="bg-"]) .card-header {
	color: #212832;
}
.dataTables_wrapper {
	--bs-gutter-x: 0;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.sl-wrapper {
	z-index: 1069;
}
.sl-overlay {
	z-index: 1068;
}
.sl-wrapper .sl-close {
	height: 4rem;
	width: 4rem;
	line-height: 4rem;
	font-size: 4.5rem;
}
.tox .tox-dialog {
	z-index: 1072 !important;
}
.tox-promotion, .tox-statusbar__branding {
	display: none;
}
.error-page {
	background-color: #fff;
}
.error {
	color: #323f52;
	font-size: 7rem;
	position: relative;
	line-height: 1;
	width: 12.5rem;
}
.previewMail {
	font-size: 1.2em;
}
.previewMail table {
	width: 100%;
	border-collapse: collapse;
}
.previewMail table, .previewMail th, .previewMail td {
	border-bottom: 1px solid #089fe3;
}
.previewMail th, .previewMail td {
	padding: 9px;
	text-align: left;
}
.previewMail tr:hover {background-color: #f5f5f5;}
.previewMail tr:nth-child(even) {background-color: #f2f2f2;}
/* Hide input type number up & down arrows on Chrome, Safari, Edge, Opera and then on Firefox */
input::-webkit-outer-spin-button:read-only,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number]:read-only {
	-moz-appearance: textfield;
	background: #DEDEDE;
}
/* FullCalandar JS Customization */
@media (max-width: 768px) {
	.fc .fc-toolbar-title {
		font-size: 1.36em;
	}
}
@media (max-width: 576px) {
	.fc .fc-toolbar {
		flex-direction: column;
	}
	.fc .fc-toolbar-chunk {
		width: 100%;
		text-align: center;
	}
	.fc .fc-toolbar-title {
		font-size: 1.25em;
		margin: 0.2rem 0;
	}
}
/* Dark Mode Switch v1.0.1 (https://github.com/coliff/dark-mode-switch) */
.dropdown-menu .form-check {
	margin-left: 4px;
}
[data-theme="dark"], [data-theme="dark"] .card, [data-theme="dark"] .text-dark:not(.bg-light,.bg-gradient-light), [data-theme="dark"] .table, [data-theme="dark"] .sidenav-light, [data-theme="dark"] .accordion-item, [data-theme="dark"] .modal-content {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
[data-theme="dark"] .card {
	background-color:#38383b;background-image:linear-gradient(180deg,#38383b 10%,#373840);background-size:cover;
}
[data-theme="dark"] .card:not([class*="bg-"]) .card-header {
	color: #FFFFFF;
	border-bottom: 1px solid #69707a;
}
[data-theme="dark"] .sidenav-light .sidenav-menu .nav-link, [data-theme="dark"] .navbar-light .navbar-nav .nav-link, [data-theme="dark"] .btn-transparent-dark, [data-theme="dark"] .topnav.navbar-light .navbar-brand, [data-theme="dark"] .btn-dark, [data-theme="dark"] .btn-outline-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
[data-theme="dark"] .sidenav-light .sidenav-menu .nav-link.active {
	color: #089fe3 !important;
}
[data-theme="dark"] .btn-close{
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
[data-theme="dark"] h6, [data-theme="dark"] .h6, [data-theme="dark"] h5, [data-theme="dark"] .h5, [data-theme="dark"] h4, [data-theme="dark"] .h4, [data-theme="dark"] h3, [data-theme="dark"] .h3, [data-theme="dark"] h2, [data-theme="dark"] .h2, [data-theme="dark"] h1, [data-theme="dark"] .h1 {
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
[data-theme="dark"] .btn-dark, [data-theme="dark"] .btn-outline-dark {
	border-color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
[data-theme="dark"] .bg-black {
	background-color:#858796;background-image:linear-gradient(180deg,#858796 10%,#60616f);background-size:cover;
}
[data-theme="dark"] .bg-dark {
	background-color: #333 !important;
}
[data-theme="dark"] .bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
[data-theme="dark"] .bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
/* Fixing td background overlayed by odd tr class when table is stripped */
table.dataTable.table-striped > tbody > tr.odd > td.table-primary, table.dataTable.table-striped > tbody > tr.odd > td.table-secondary, table.dataTable.table-striped > tbody > tr.odd > td.table-success, table.dataTable.table-striped > tbody > tr.odd > td.table-danger, table.dataTable.table-striped > tbody > tr.odd > td.table-warning, table.dataTable.table-striped > tbody > tr.odd > td.table-dark, table.dataTable.table-striped > tbody > tr.odd > td.table-info, table.dataTable.table-striped > tbody > tr.odd > td.table-light {
	--bs-table-accent-bg: transparent;
}
.table-primary {
	--bs-bg-opacity: 0.2;
	background-color: rgba(var(--bs-primary-rgb),var(--bs-bg-opacity)) !important;
}
.table-secondary {
	--bs-bg-opacity: 0.2;
	background-color: rgba(var(--bs-secondary-rgb),var(--bs-bg-opacity)) !important;
}
.table-success {
	--bs-bg-opacity: 0.2;
	background-color: rgba(var(--bs-success-rgb),var(--bs-bg-opacity)) !important;
}
.table-danger {
	--bs-bg-opacity: 0.2;
	background-color: rgba(var(--bs-danger-rgb),var(--bs-bg-opacity)) !important;
}
.table-warning {
	--bs-bg-opacity: 0.2;
	background-color: rgba(var(--bs-warning-rgb),var(--bs-bg-opacity)) !important;
}
.table-dark {
	--bs-bg-opacity: 0.36;
	background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity)) !important;
}
.table-info {
	--bs-bg-opacity: 0.2;
	background-color: rgba(var(--bs-info-rgb),var(--bs-bg-opacity)) !important;
}
.table-light {
	--bs-bg-opacity: 0.2;
	background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity)) !important;
}
/* badges classes */
@-webkit-keyframes noise-anim {
	0% {
		clip: rect(25px, 9999px, 57px, 0);
	}
	5% {
		clip: rect(24px, 9999px, 71px, 0);
	}
	10% {
		clip: rect(67px, 9999px, 31px, 0);
	}
	15% {
		clip: rect(38px, 9999px, 62px, 0);
	}
	20% {
		clip: rect(43px, 9999px, 76px, 0);
	}
	25% {
		clip: rect(85px, 9999px, 90px, 0);
	}
	30% {
		clip: rect(83px, 9999px, 58px, 0);
	}
	35% {
		clip: rect(48px, 9999px, 20px, 0);
	}
	40% {
		clip: rect(49px, 9999px, 29px, 0);
	}
	45% {
		clip: rect(12px, 9999px, 82px, 0);
	}
	50% {
		clip: rect(85px, 9999px, 71px, 0);
	}
	55% {
		clip: rect(83px, 9999px, 56px, 0);
	}
	60% {
		clip: rect(89px, 9999px, 40px, 0);
	}
	65% {
		clip: rect(38px, 9999px, 95px, 0);
	}
	70% {
		clip: rect(17px, 9999px, 24px, 0);
	}
	75% {
		clip: rect(17px, 9999px, 61px, 0);
	}
	80% {
		clip: rect(25px, 9999px, 64px, 0);
	}
	85% {
		clip: rect(80px, 9999px, 56px, 0);
	}
	90% {
		clip: rect(95px, 9999px, 34px, 0);
	}
	95% {
		clip: rect(91px, 9999px, 40px, 0);
	}
	100% {
		clip: rect(25px, 9999px, 2px, 0);
	}
}
@keyframes noise-anim {
	0% {
		clip: rect(25px, 9999px, 57px, 0);
	}
	5% {
		clip: rect(24px, 9999px, 71px, 0);
	}
	10% {
		clip: rect(67px, 9999px, 31px, 0);
	}
	15% {
		clip: rect(38px, 9999px, 62px, 0);
	}
	20% {
		clip: rect(43px, 9999px, 76px, 0);
	}
	25% {
		clip: rect(85px, 9999px, 90px, 0);
	}
	30% {
		clip: rect(83px, 9999px, 58px, 0);
	}
	35% {
		clip: rect(48px, 9999px, 20px, 0);
	}
	40% {
		clip: rect(49px, 9999px, 29px, 0);
	}
	45% {
		clip: rect(12px, 9999px, 82px, 0);
	}
	50% {
		clip: rect(85px, 9999px, 71px, 0);
	}
	55% {
		clip: rect(83px, 9999px, 56px, 0);
	}
	60% {
		clip: rect(89px, 9999px, 40px, 0);
	}
	65% {
		clip: rect(38px, 9999px, 95px, 0);
	}
	70% {
		clip: rect(17px, 9999px, 24px, 0);
	}
	75% {
		clip: rect(17px, 9999px, 61px, 0);
	}
	80% {
		clip: rect(25px, 9999px, 64px, 0);
	}
	85% {
		clip: rect(80px, 9999px, 56px, 0);
	}
	90% {
		clip: rect(95px, 9999px, 34px, 0);
	}
	95% {
		clip: rect(91px, 9999px, 40px, 0);
	}
	100% {
		clip: rect(25px, 9999px, 2px, 0);
	}
}
.error:after {
	content: attr(data-text);
	position: absolute;
	left: 2px;
	text-shadow: -1px 0 #e81500;
	top: 0;
	color: #323f52;
	background: #fff;
	overflow: hidden;
	clip: rect(0, 900px, 0, 0);
	animation: noise-anim 2s infinite linear alternate-reverse;
}
@-webkit-keyframes noise-anim-2 {
	0% {
		clip: rect(93px, 9999px, 12px, 0);
	}
	5% {
		clip: rect(30px, 9999px, 94px, 0);
	}
	10% {
		clip: rect(37px, 9999px, 71px, 0);
	}
	15% {
		clip: rect(31px, 9999px, 77px, 0);
	}
	20% {
		clip: rect(75px, 9999px, 31px, 0);
	}
	25% {
		clip: rect(90px, 9999px, 92px, 0);
	}
	30% {
		clip: rect(44px, 9999px, 70px, 0);
	}
	35% {
		clip: rect(44px, 9999px, 76px, 0);
	}
	40% {
		clip: rect(51px, 9999px, 68px, 0);
	}
	45% {
		clip: rect(78px, 9999px, 79px, 0);
	}
	50% {
		clip: rect(58px, 9999px, 27px, 0);
	}
	55% {
		clip: rect(51px, 9999px, 43px, 0);
	}
	60% {
		clip: rect(23px, 9999px, 45px, 0);
	}
	65% {
		clip: rect(74px, 9999px, 72px, 0);
	}
	70% {
		clip: rect(64px, 9999px, 58px, 0);
	}
	75% {
		clip: rect(37px, 9999px, 8px, 0);
	}
	80% {
		clip: rect(14px, 9999px, 73px, 0);
	}
	85% {
		clip: rect(18px, 9999px, 91px, 0);
	}
	90% {
		clip: rect(37px, 9999px, 26px, 0);
	}
	95% {
		clip: rect(82px, 9999px, 40px, 0);
	}
	100% {
		clip: rect(23px, 9999px, 53px, 0);
	}
}
@keyframes noise-anim-2 {
	0% {
		clip: rect(93px, 9999px, 12px, 0);
	}
	5% {
		clip: rect(30px, 9999px, 94px, 0);
	}
	10% {
		clip: rect(37px, 9999px, 71px, 0);
	}
	15% {
		clip: rect(31px, 9999px, 77px, 0);
	}
	20% {
		clip: rect(75px, 9999px, 31px, 0);
	}
	25% {
		clip: rect(90px, 9999px, 92px, 0);
	}
	30% {
		clip: rect(44px, 9999px, 70px, 0);
	}
	35% {
		clip: rect(44px, 9999px, 76px, 0);
	}
	40% {
		clip: rect(51px, 9999px, 68px, 0);
	}
	45% {
		clip: rect(78px, 9999px, 79px, 0);
	}
	50% {
		clip: rect(58px, 9999px, 27px, 0);
	}
	55% {
		clip: rect(51px, 9999px, 43px, 0);
	}
	60% {
		clip: rect(23px, 9999px, 45px, 0);
	}
	65% {
		clip: rect(74px, 9999px, 72px, 0);
	}
	70% {
		clip: rect(64px, 9999px, 58px, 0);
	}
	75% {
		clip: rect(37px, 9999px, 8px, 0);
	}
	80% {
		clip: rect(14px, 9999px, 73px, 0);
	}
	85% {
		clip: rect(18px, 9999px, 91px, 0);
	}
	90% {
		clip: rect(37px, 9999px, 26px, 0);
	}
	95% {
		clip: rect(82px, 9999px, 40px, 0);
	}
	100% {
		clip: rect(23px, 9999px, 53px, 0);
	}
}
.error:before {
	content: attr(data-text);
	position: absolute;
	left: -2px;
	text-shadow: 1px 0 #0061f2;
	top: 0;
	color: #323f52;
	background: #fff;
	overflow: hidden;
	clip: rect(0, 900px, 0, 0);
	animation: noise-anim-2 3s infinite linear alternate-reverse;
}
